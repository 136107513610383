<style type="text/css">
@import "~@/assets/css/common.css";
.selectClass {
  width: 180px;
  margin-right: 20px;
}
</style>
<script>
import $ from "jquery";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  getExamList,
  getUserExamList,
  getHMDK,
  getWJK,
  userSpecial,
  getSpecialExamList,
} from "@/api/examination.js";
import { userInfo } from "@/api/userInfo";
import { format, getUserInfo, getDictionary } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "报名项目列表",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名列表",
          active: true,
        },
      ],
      userInfo: {},
      postData: {
        ksnf: "",
      },
      nfList: [],
      bmztList: [
        { name: "全部", value: "" },
        { name: "未开始", value: "0" },
        { name: "正在报名", value: "1" },
        { name: "已结束", value: "2" },
      ],
      flList: [],
      lbList: [],
      examList: [],
      recordList: [],
      chooseIndex: "",
    };
  },
  methods: {
    getUserExam() {
      getUserExamList().then((res) => {
        if (res.status) {
          this.recordList = res.data;
        }
      });
    },
    qualification(ksbmbh) {
      return new Promise((resolve, reject) => {
        let data = this.examList[this.chooseIndex];
        if (data.sfqyhmdk) {
          // 判断是否在黑名单库
          getHMDK(ksbmbh).then((res) => {
            if (res.status) {
              if (res.data && res.data.length != 0) {
                this.$message({
                  type: "error",
                  message: "对不起，您不具备报名资格",
                });
              } else {
                if (data.sfqywjk) {
                  // 判断是否在违纪库
                  getWJK(ksbmbh).then((res) => {
                    if (res.status) {
                      if (res.data && res.data.length != 0) {
                        let data = res.data[res.data.length - 1];
                        this.$message({
                          type: "error",
                          message:
                            "对不起，您不具备报名资格,原因：" +
                            data.ksmc +
                            data.wjyy,
                        });
                        resolve(false);
                      } else {
                        resolve(true);
                      }
                    }
                  });
                } else {
                  resolve(true);
                }
              }
            } else {
              resolve(false);
            }
          });
        } else if (data.sfqywjk) {
          // 判断是否在违纪库
          getWJK(ksbmbh).then((res) => {
            if (res.status) {
              if (res.data && res.data.length != 0) {
                let data = res.data[res.data.length - 1];
                this.$message({
                  type: "error",
                  message:
                    "对不起，您不具备报名资格,原因：" + data.ksmc + data.wjyy,
                });
                resolve(false);
              } else {
                resolve(true);
              }
            }
          });
        } else {
          resolve(true);
        }
      });
    },

    signUp(index) {
      let data = this.examList[index];
      this.chooseIndex = index;
      if (this.userInfo.zpzt == 0 || !this.userInfo.zpzt) {
        this.$message({
          type: "error",
          message: "照片信息未完善，请先去处理照片信息",
        });
        return;
      }
      if ((this.userInfo.xlzt == 0 || !this.userInfo.xlzt) && data.xlzdsh) {
        this.$message({
          type: "error",
          message: "学历信息未上传，请先上传学历信息",
        });
        this.$router.push("/personalEducation");
        return;
      }
      if (
        data.yxbmzjlx &&
        !data.yxbmzjlx.split(",").filter((v) => {
          return v == this.userInfo.sfzjlxm;
        })[0]
      ) {
        getDictionary("dict_sfzjlx").then((res) => {
          let list = res["dict_sfzjlx"].filter((v) => {
            return data.yxbmzjlx.includes(v.value);
          });
          let str = "";
          list.forEach((v) => {
            str += v.name + "、";
          });
          str = str.slice(0, str.length - 1);
          this.$message({
            type: "error",
            message: "该考试只允许以" + str + "注册的用户报名",
          });
        });
        return;
      }
      //跳转页面
      let ksbmbh = data.ksbmbh;
      let record = this.recordList.filter((item) => {
        return item.ksbmbh == ksbmbh;
      })[0];

      // 已有报名数据
      if (record) {
        // 未提交且未审核
        if ((record.zgshzt == 0 || record.zgshzt == null) && !record.sfzstj) {
          this.$router.push({
            path: "./enrollForm",
            query: {
              ksbmbh: ksbmbh,
              bmh: record.bmh,
            },
          });
        } else {
          console.log(record);
          // return
          this.$router.push({
            path: "./record",
            query: {
              ksbmbh: ksbmbh,
              bmh: record.bmh,
            },
          });
          this.$message({
            type: "error",
            message: "已有报名记录",
          });
          return;
        }
      } else {
        this.qualification(ksbmbh).then((status) => {
          if (status) {
            this.$router.push({
              path: "/agreement",
              query: {
                ksbmbh: ksbmbh,
              },
            });
          }
        });
      }
    },

    getExam() {
      getSpecialExamList().then((res) => {
        if (res.status) {
          if (res.data) {
            this.examList = this.examStatus(res.data);
          }
        }
      });
    },
    examStatus(data) {
      let date = new Date().getTime();
      data.forEach((item) => {
        item.ksbmzt_ = "特殊报名";
        // let kssj = new Date(item.bmkssj).getTime();
        // let jssj = new Date(item.bmjssj).getTime();
        // if (date < kssj) {
        //   item.ksbmzt_ = "未开始";
        // } else if (kssj < date && date < jssj) {
        //   item.ksbmzt_ = "正在报名";
        // } else {
        //   item.ksbmzt_ = "已结束";
        // }
      });
      return data;
    },
    getUserInfo() {
      getUserInfo().then((data) => {
        this.userInfo = data;
        return;
      });
    },
    init() {
      this.getExam();
      this.getUserInfo();
      this.getUserExam();
    },
  },
  created() {
    // let year = new Date().getFullYear();
    // this.nfList = [year, year - 1, year - 2];
    // this.postData.ksnf = year;
    this.init();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">以下是为系统报名项目</span>
              <!-- <el-select
                v-model="postData.ksnf"
                placeholder="请选择考试发布年份"
                class="selectClass"
                @change="getExam"
              >
                <el-option
                  v-for="item in nfList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select> -->
            </h4>
            <div class="table-responsive border mt-3">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th>考试名称</th>
                    <th style="width: 25%">报名起止时间</th>
                    <th style="width: 25%">缴费时间</th>
                    <th style="width: 10%">报名状态</th>
                    <th style="width: 10%">操作</th>
                  </tr>
                </thead>
                <tbody v-if="examList.length != 0">
                  <tr v-for="(item, index) in examList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <a
                        :href="
                          item.bmtzh
                            ? '/announcementDetails?newsId=' + item.bmtzh
                            : 'javascript:void(0);'
                        "
                        target="_bank"
                        class="blue-font"
                        >{{ item.ksmc }}</a
                      >
                    </td>
                    <td>
                      {{ item.bmkssj }} <span class="m-1">至</span>
                      {{ item.bmjssj }}
                    </td>
                    <td>
                      {{ item.jfkssj }}<span class="m-1">至</span>
                      {{ item.jfjssj }}
                    </td>
                    <td>{{ item.ksbmzt_ }}</td>
                    <td
                      v-if="item.ksbmzt_ == '特殊报名'"
                      class="table-btn"
                      :index="index"
                    >
                      <button
                        type="button"
                        class="btn btn-info h30"
                        @click="signUp(index)"
                      >
                        <i class="iconfont icon-come-l mr-1"></i>立即报名
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center" v-if="examList.length == 0">
              无可报名考试
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
